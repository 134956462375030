/**** Fade Animation ****/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.home-page {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 21px;
}

.fade-effect {
  display: block; /* Change display inline to block */
  animation: fade 1s;
}

.page-fade-effect {
  animation: fade 1s;
}

.text-uppercase {
  text-transform: uppercase;
}

.page-title-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.long-bg {
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100vw;
  width: 100%;
  top: 0;
  align-items: center;
}

.fixed {
  position: fixed;
}

.page-title-block {
  font-weight: 800;
  font-size: 9rem;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.content-wrapper {
  text-align: center;
  margin-bottom: 7.5rem;
}
.content-headline {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2rem;
  padding: 3rem;
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  max-width: 800px;
  display: inline-block;
  margin: 0 auto 5rem;
}
.content-text {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000;
  text-align: center;
  max-width: 970px;
  margin: 0;
}

.content-text-sm {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  max-width: 966px;
}

.forever {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  margin-top: 15rem;
  margin-bottom: 2rem;
}

.getabone {
  display: flex;
  justify-content: center;
  margin-bottom: 20rem;
}

@media screen and (max-width: 1280px) {
  .page-title-block {
    font-size: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .long-bg {
    background-size: 220%;
    background-position: center 0%;
  }
  .bs-mobile-cover {
    background-size: cover;
  }
  .page-title-block {
    font-size: 3rem;
  }
  .content-wrapper {
    margin-bottom: 5rem;
  }
  .content-headline {
    padding: 1rem;
    border-radius: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
  }
  .content-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .forever {
    font-size: 1.5rem;
    margin-top: 12.5rem;
  }
  .getabone {
    margin-bottom: 15rem;
  }
}

.mb-0 {
  margin-bottom: 0;
}
