.dropdown-list {
  position: absolute;
  z-index: 2;
}

.dd-wrapper .dropdown-list {
  padding-top: 0.3125rem;
}

.dd-wrapper .dropdown-list li a {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 0.3125rem;
}
/**** Fade Animation ****/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-effect {
  display: block; /* Change display inline to block */
  animation: fade 1s;
}

.item-text {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.bold-text {
  font-weight: 800;
}
