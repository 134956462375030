.no-scroll {
  background-image: url('../../assets/images/BG2.png');
  background-size: 100vw;
  margin-right: 22.54px;
}

@media screen and (max-width: 768px) {
  .no-scroll {
    background-image: url('../../assets/images/HOME - BONEHEADS - MOBILE.png');
    background-size: cover;
  }
}
