.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem;
  position: relative;
  z-index: 4;
  box-sizing: border-box;
  width: 100%;
}
.footer .tos {
  text-decoration: underline;
}
.footer .left-nav {
  display: flex;
}
.footer .left-nav > * {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}
.footer .left-nav > *:first-child {
  margin-left: 0;
}
.footer .left-nav > *:last-child {
  margin-right: 0;
}
.footer .right-nav {
  font-size: 0.875rem;
}
.footer .left-nav.desktop {
  display: flex;
}
.footer .left-nav.mobile {
  display: none;
}
.contract-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 18px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  cursor: pointer;
  margin-right: 70px;
}
.contract-btn img {
  width: 14px;
  height: 18px;
}
.contract-btn span {
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin-left: 12px;
}
.hide-left-nav {
  /* DO NOT remove this class */
}

@media screen and (max-width: 768px) {
  .footer .left-nav.desktop {
    display: none;
  }
  .footer .left-nav.mobile {
    display: flex;
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    position: relative;
  }
  .footer .right-nav {
    font-size: 0.625rem;
    margin-top: 0.5rem;
  }
  .contract-btn {
    margin: 0 0 16px 0;
  }
  .hide-left-nav {
    display: none !important;
  }
}
