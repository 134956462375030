.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  z-index: 4;
  box-sizing: border-box;
  height: 5rem;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: top 0.3s ease-in-out;
}

.header .left-nav {
  display: flex;
}
.header .left-nav > *,
.header .right-nav > * {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}
.header .left-nav > *:first-child,
.header .right-nav > *:first-child {
  margin-left: 0;
}
.header .left-nav > *:last-child,
.header .right-nav > *:last-child {
  margin-right: 0;
}
.header .center-nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.header .right-nav {
  display: flex;
}

.header .left-nav.desktop {
  display: flex;
}
.header .left-nav.mobile {
  display: none;
}
.desktop {
  display: flex;
}
.mobile {
  display: none;
}
.item-text {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.bold-text {
  font-weight: 800;
}

@media screen and (max-width: 768px) {
  .header .left-nav.desktop {
    display: none;
  }
  .header .left-nav.mobile {
    display: flex;
  }
  .header .right-nav.mobile-nav .mobile {
    display: flex;
    margin-right: 0;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
}
