.br-24p {
  border-radius: 24px;
}

.menu-item-holder {
  display: flex;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.25); old */
  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  margin: auto;
  /* margin-top: 1rem; */
  padding: 0.75em 1.125em;
}
.menu-item-holder:hover {
  background: rgba(255, 255, 255, 0.75);
}
.menu-item-holder .item-text {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.dropdownItem {
  background: rgba(255, 255, 255, 0.75);
}
.dropdownItem:hover {
  background: rgba(255, 255, 255, 1);
}
.locked-menu-item,
.locked-menu-item:hover {
  background: rgba(255, 255, 255, 0.5);
}

.main-btn {
  padding: 1.5rem;
  background: hsla(0, 0%, 100%, 0.5);
  border-radius: 24px;
}

.list-item-mobile {
    background: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
  .main-btn {
    padding: 0.813rem 1.125rem;
    border-radius: .75rem;
  }
}
