.main-page-icon {
  height: 38px;
  width: 38px;
}

@media screen and (max-width: 768px) {
  .main-page-icon {
    height: 24px;
    width: 24px;
  }
}
