.text-green {
  color: #006516;
}
.text-red {
  color: #b50000;
}
.text-purple {
  color: #9e00ff;
}
a {
  text-decoration: none;
  color: #000;
}
.click-handler {
  display: block;
}
.item-text {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.bold-text {
  font-weight: 800;
}
p {
  margin-bottom: 4rem;
}
p:last-child {
  margin-bottom: 0;
}
.mb-10r {
  margin-bottom: 10rem;
}

.mt-6-25r {
  margin-top: 6.25rem;
}

@media screen and (max-width: 768px) {
  .mb-10r {
    margin-bottom: 5rem;
  }
  .mt-6-25r {
    margin-top: 3rem;
  }
}
