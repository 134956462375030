.context-text-list {
  text-align: center;
  list-style: none;
}
.content-text p {
  margin-bottom: 4rem;
}
.content-text p:last-child {
  margin-bottom: 0;
}
.text-white {
  color: #fff;
}
.item-text {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.bold-text {
  font-weight: 800;
}