.item-text {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.bold-text {
  font-weight: 800;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
li {
  display: flex;
}
li a {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 0.3125rem;
}

.locked-icon {
  margin-left: 13px;
}
