@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,800;0,900;1,900&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: #000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 768px) {
  html,
  body {
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: var(--app-height);
  }
}

/***** TODO - will also be removed this section in mint pages redesign *****/

.connect-meta .menu-item-holder {
  background: rgba(183, 240, 255, 0.9);
}
.connect-meta .menu-item-holder img {
  width: 38px;
  height: 38px;
}

.mint-row {
  display: flex;
}
.mint-row .item-holder {
  width: auto;
  height: auto;
}
.mint-row .dropdown {
  display: flex;
}
.mint-row .menu-item-holder:nth-of-type(2) {
  margin-left: 1rem;
}
.mint-row .dropdown-list {
  font-size: 1.1rem;
}
.mint-row .dropdown-list li a {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}
.mint-row .dropdown-list li a .menu-item-holder {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}
.mint-row .dropdown-list img {
  width: 20px;
}

.boneheadRemain {
  font-family: Montserrat;
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
  line-height: 116px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
}

.totalMinted {
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
}

.etherscanlink {
  padding-top: 5%;
}

@media (max-width: 600px) {
  .etherscanlink {
    overflow-wrap: break-word;
    font-size: 8px;
  }
}
