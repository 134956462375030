.item-text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  padding-left: 0.75rem;
  font-weight: 800;
}
.bold-text {
  font-weight: 800;
}
.text-22 {
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .mail-text {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}
