.item-holder {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  margin: auto;
  height: 3rem;
  width: 3rem;
  justify-content: center;
}
.item-holder:hover {
  background: rgba(255, 255, 255, 0.75);
}
