@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,800;0,900;1,900&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: #000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 768px) {
  html,
  body {
    padding: 0;
    margin: 0;
    width: 100vw;
    min-height: var(--app-height);
  }
}

/***** TODO - will also be removed this section in mint pages redesign *****/

.connect-meta .menu-item-holder {
  background: rgba(183, 240, 255, 0.9);
}
.connect-meta .menu-item-holder img {
  width: 38px;
  height: 38px;
}

.mint-row {
  display: -webkit-flex;
  display: flex;
}
.mint-row .item-holder {
  width: auto;
  height: auto;
}
.mint-row .dropdown {
  display: -webkit-flex;
  display: flex;
}
.mint-row .menu-item-holder:nth-of-type(2) {
  margin-left: 1rem;
}
.mint-row .dropdown-list {
  font-size: 1.1rem;
}
.mint-row .dropdown-list li a {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}
.mint-row .dropdown-list li a .menu-item-holder {
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}
.mint-row .dropdown-list img {
  width: 20px;
}

.boneheadRemain {
  font-family: Montserrat;
  font-size: 96px;
  font-style: normal;
  font-weight: 800;
  line-height: 116px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
}

.totalMinted {
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
}

.etherscanlink {
  padding-top: 5%;
}

@media (max-width: 600px) {
  .etherscanlink {
    overflow-wrap: break-word;
    font-size: 8px;
  }
}

.app_page-container__1gZv9 {
  background: #27c3ec;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
}

.app_main-container__X-tis {
  background: #000;
}

/***** Loader animation *****/
.app_loader-bg__1ZTvC {
  background: #000;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9;
}

.app_loader-container__3ZOBu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  margin: auto;
  position: relative;
  outline: 1px solid transparent;
}
.app_loader-container__3ZOBu .app_brand-name__2DYpu {
  position: absolute;
  width: 90%;
  -webkit-animation: app_brand-name-animation__rfL_6 3s ease-in 1;
          animation: app_brand-name-animation__rfL_6 3s ease-in 1;
}

.app_loader-container__3ZOBu .app_brand-name__2DYpu:before {
  content: ' ';
  background: radial-gradient(circle at center, transparent 0, black 40%);
  background-size: 200% 500%;
  -webkit-animation: app_brand-name-gradient-animation__3dMxm 5s ease-in 1;
          animation: app_brand-name-gradient-animation__3dMxm 5s ease-in 1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-position: -50%;
}

.app_loader-container__3ZOBu .app_logo__2B4qm {
  position: absolute;
  width: 50vw;
  max-width: 272px;
}

.app_loader-container__3ZOBu .app_logo__2B4qm:before {
  content: ' ';
  background: radial-gradient(circle at center, transparent 0, black 40%);
  background-size: 500% 500%;
  -webkit-animation: app_logo-gradient-animation__2wXfw 3s ease-in 1;
          animation: app_logo-gradient-animation__2wXfw 3s ease-in 1;
  width: 101%;
  height: 101%;
  display: block;
  position: absolute;
  background-position: 0;
  /* -webkit-backface-visibility: hidden */
}

@-webkit-keyframes app_brand-name-gradient-animation__3dMxm {
  0% {
    background-position: 150%;
  }
  50% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}

@keyframes app_brand-name-gradient-animation__3dMxm {
  0% {
    background-position: 150%;
  }
  50% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}

@-webkit-keyframes app_logo-gradient-animation__2wXfw {
  0% {
    background-position: 101% 101%;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes app_logo-gradient-animation__2wXfw {
  0% {
    background-position: 101% 101%;
  }
  100% {
    background-position: 0 0;
  }
}

@-webkit-keyframes app_brand-name-animation__rfL_6 {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes app_brand-name-animation__rfL_6 {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**** Fade Animation ****/
@-webkit-keyframes app_fade__3V4Sh {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes app_fade__3V4Sh {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app_fade-effect__1EDkv {
  display: block; /* Change display inline to block */
  -webkit-animation: app_fade__3V4Sh 1s;
          animation: app_fade__3V4Sh 1s;
}

@media screen and (max-width: 768px) {
  .app_page-container__1gZv9 {
    min-height: var(--app-height);
  }
}

.layout_container__3S34L {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}

.layout_main-page__18Gz6 {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

/**** Fade Animation ****/
@-webkit-keyframes layout_fade__2cL8Y {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes layout_fade__2cL8Y {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.layout_fade-effect__3CyJV {
  display: block; /* Change display inline to block */
  -webkit-animation: layout_fade__2cL8Y 1s;
          animation: layout_fade__2cL8Y 1s;
}

@media screen and (max-width: 768px) {
  .layout_container__3S34L {
    min-height: var(--app-height);
  }
}

.header_header__HZ6Fg {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem;
  position: fixed;
  z-index: 4;
  box-sizing: border-box;
  height: 5rem;
  width: 100%;
  transition: top 0.3s ease-in-out;
}

.header_header__HZ6Fg .header_left-nav__16b69 {
  display: -webkit-flex;
  display: flex;
}
.header_header__HZ6Fg .header_left-nav__16b69 > *,
.header_header__HZ6Fg .header_right-nav__3KL57 > * {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}
.header_header__HZ6Fg .header_left-nav__16b69 > *:first-child,
.header_header__HZ6Fg .header_right-nav__3KL57 > *:first-child {
  margin-left: 0;
}
.header_header__HZ6Fg .header_left-nav__16b69 > *:last-child,
.header_header__HZ6Fg .header_right-nav__3KL57 > *:last-child {
  margin-right: 0;
}
.header_header__HZ6Fg .header_center-nav__3JVvW {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.header_header__HZ6Fg .header_right-nav__3KL57 {
  display: -webkit-flex;
  display: flex;
}

.header_header__HZ6Fg .header_left-nav__16b69.header_desktop__2EqoU {
  display: -webkit-flex;
  display: flex;
}
.header_header__HZ6Fg .header_left-nav__16b69.header_mobile__1UNOc {
  display: none;
}
.header_desktop__2EqoU {
  display: -webkit-flex;
  display: flex;
}
.header_mobile__1UNOc {
  display: none;
}
.header_item-text__2s8KU {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.header_bold-text__3HF1X {
  font-weight: 800;
}

@media screen and (max-width: 768px) {
  .header_header__HZ6Fg .header_left-nav__16b69.header_desktop__2EqoU {
    display: none;
  }
  .header_header__HZ6Fg .header_left-nav__16b69.header_mobile__1UNOc {
    display: -webkit-flex;
    display: flex;
  }
  .header_header__HZ6Fg .header_right-nav__3KL57.header_mobile-nav__cv81R .header_mobile__1UNOc {
    display: -webkit-flex;
    display: flex;
    margin-right: 0;
  }
  .header_desktop__2EqoU {
    display: none;
  }
  .header_mobile__1UNOc {
    display: -webkit-flex;
    display: flex;
  }
}

.menu-item-holder_br-24p__2l8vO {
  border-radius: 24px;
}

.menu-item-holder_menu-item-holder__3YB2N {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* background: rgba(255, 255, 255, 0.25); old */
  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  margin: auto;
  /* margin-top: 1rem; */
  padding: 0.75em 1.125em;
}
.menu-item-holder_menu-item-holder__3YB2N:hover {
  background: rgba(255, 255, 255, 0.75);
}
.menu-item-holder_menu-item-holder__3YB2N .menu-item-holder_item-text__3v1Ff {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.menu-item-holder_dropdownItem__2qckG {
  background: rgba(255, 255, 255, 0.75);
}
.menu-item-holder_dropdownItem__2qckG:hover {
  background: rgba(255, 255, 255, 1);
}
.menu-item-holder_locked-menu-item__T8FRm,
.menu-item-holder_locked-menu-item__T8FRm:hover {
  background: rgba(255, 255, 255, 0.5);
}

.menu-item-holder_main-btn__1O5tu {
  padding: 1.5rem;
  background: hsla(0, 0%, 100%, 0.5);
  border-radius: 24px;
}

.menu-item-holder_list-item-mobile__1lNKS {
    background: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
  .menu-item-holder_main-btn__1O5tu {
    padding: 0.813rem 1.125rem;
    border-radius: .75rem;
  }
}

.icon-holder_item-holder__2sqY6 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  margin: auto;
  height: 3rem;
  width: 3rem;
  -webkit-justify-content: center;
          justify-content: center;
}
.icon-holder_item-holder__2sqY6:hover {
  background: rgba(255, 255, 255, 0.75);
}

.icon_main-page-icon__302_p {
  height: 38px;
  width: 38px;
}

@media screen and (max-width: 768px) {
  .icon_main-page-icon__302_p {
    height: 24px;
    width: 24px;
  }
}

.dropdown_dropdown-list__3YObU {
  position: absolute;
  z-index: 2;
}

.dropdown_dd-wrapper__3Di2S .dropdown_dropdown-list__3YObU {
  padding-top: 0.3125rem;
}

.dropdown_dd-wrapper__3Di2S .dropdown_dropdown-list__3YObU li a {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 0.3125rem;
}
/**** Fade Animation ****/
@-webkit-keyframes dropdown_fade__1GOwP {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dropdown_fade__1GOwP {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dropdown_fade-effect__RMQOZ {
  display: block; /* Change display inline to block */
  -webkit-animation: dropdown_fade__1GOwP 1s;
          animation: dropdown_fade__1GOwP 1s;
}

.dropdown_item-text__xXB_a {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.dropdown_bold-text__1hTe6 {
  font-weight: 800;
}

.dropdown-component_dropdown__sxAqE {
  position: relative;
}
.dropdown-component_dropdown-list__1LIRI {
  position: absolute;
  z-index: 2;
}
.dropdown-component_dropdown__sxAqE .dropdown-component_dropdown-list__1LIRI {
  padding-top: 0.3125rem;
}

.dropdown-component_dropdown__sxAqE .dropdown-component_dropdown-list__1LIRI li {
  display: inline-block;
}

/**** Fade Animation ****/
@-webkit-keyframes dropdown-component_fade__mlO4C {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dropdown-component_fade__mlO4C {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dropdown-component_fade-effect__1WoBV {
  display: block; /* Change display inline to block */
  -webkit-animation: dropdown-component_fade__mlO4C 1s;
          animation: dropdown-component_fade__mlO4C 1s;
}

.dropdown-list_item-text__39BgN {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.dropdown-list_bold-text__1ZCNR {
  font-weight: 800;
}
.dropdown-list_flex__1YA64 {
  display: -webkit-flex;
  display: flex;
}
.dropdown-list_align-center__2MNrq {
  -webkit-align-items: center;
          align-items: center;
}
li {
  display: -webkit-flex;
  display: flex;
}
li a {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-transform: uppercase;
  white-space: nowrap;
  margin-right: 0.3125rem;
}

.dropdown-list_locked-icon__1G9nq {
  margin-left: 13px;
}

.footer_footer__2oW47 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding: 1rem;
  position: relative;
  z-index: 4;
  box-sizing: border-box;
  width: 100%;
}
.footer_footer__2oW47 .footer_tos__3KwyH {
  text-decoration: underline;
}
.footer_footer__2oW47 .footer_left-nav__1qzno {
  display: -webkit-flex;
  display: flex;
}
.footer_footer__2oW47 .footer_left-nav__1qzno > * {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}
.footer_footer__2oW47 .footer_left-nav__1qzno > *:first-child {
  margin-left: 0;
}
.footer_footer__2oW47 .footer_left-nav__1qzno > *:last-child {
  margin-right: 0;
}
.footer_footer__2oW47 .footer_right-nav__2yeyY {
  font-size: 0.875rem;
}
.footer_footer__2oW47 .footer_left-nav__1qzno.footer_desktop__2vTjb {
  display: -webkit-flex;
  display: flex;
}
.footer_footer__2oW47 .footer_left-nav__1qzno.footer_mobile__bBI1t {
  display: none;
}
.footer_contract-btn__1D_1a {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px 18px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  cursor: pointer;
  margin-right: 70px;
}
.footer_contract-btn__1D_1a img {
  width: 14px;
  height: 18px;
}
.footer_contract-btn__1D_1a span {
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin-left: 12px;
}
.footer_hide-left-nav__2MmKH {
  /* DO NOT remove this class */
}

@media screen and (max-width: 768px) {
  .footer_footer__2oW47 .footer_left-nav__1qzno.footer_desktop__2vTjb {
    display: none;
  }
  .footer_footer__2oW47 .footer_left-nav__1qzno.footer_mobile__bBI1t {
    display: -webkit-flex;
    display: flex;
  }
  .footer_footer__2oW47 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 1rem;
    position: relative;
  }
  .footer_footer__2oW47 .footer_right-nav__2yeyY {
    font-size: 0.625rem;
    margin-top: 0.5rem;
  }
  .footer_contract-btn__1D_1a {
    margin: 0 0 16px 0;
  }
  .footer_hide-left-nav__2MmKH {
    display: none !important;
  }
}

.about_item-text__tI9eb {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}

.secondary-page_item-text__3DqIw {
  font-size: 1.5rem;
  line-height: 2.5rem;
  padding-left: 0.75rem;
  font-weight: 800;
}
.secondary-page_bold-text__3Eweh {
  font-weight: 800;
}
.secondary-page_text-22__1pTrh {
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .secondary-page_mail-text__1bgnI {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}

/**** Fade Animation ****/
@-webkit-keyframes shared_fade__VABJw {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes shared_fade__VABJw {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shared_home-page__1__w4 {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 21px;
}

.shared_fade-effect__1RnDX {
  display: block; /* Change display inline to block */
  -webkit-animation: shared_fade__VABJw 1s;
          animation: shared_fade__VABJw 1s;
}

.shared_page-fade-effect__3N61L {
  -webkit-animation: shared_fade__VABJw 1s;
          animation: shared_fade__VABJw 1s;
}

.shared_text-uppercase__1a2k6 {
  text-transform: uppercase;
}

.shared_page-title-container__3ho9_ {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.shared_long-bg__1lKVU {
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100vw;
  width: 100%;
  top: 0;
  -webkit-align-items: center;
          align-items: center;
}

.shared_fixed__27A2D {
  position: fixed;
}

.shared_page-title-block__2RQ2b {
  font-weight: 800;
  font-size: 9rem;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.shared_content-wrapper__25COO {
  text-align: center;
  margin-bottom: 7.5rem;
}
.shared_content-headline__2kvA1 {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2rem;
  padding: 3rem;
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  max-width: 800px;
  display: inline-block;
  margin: 0 auto 5rem;
}
.shared_content-text__3wAYU {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000;
  text-align: center;
  max-width: 970px;
  margin: 0;
}

.shared_content-text-sm__TL9v- {
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  max-width: 966px;
}

.shared_forever__SsVye {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  margin-top: 15rem;
  margin-bottom: 2rem;
}

.shared_getabone__z_9PT {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 20rem;
}

@media screen and (max-width: 1280px) {
  .shared_page-title-block__2RQ2b {
    font-size: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .shared_long-bg__1lKVU {
    background-size: 220%;
    background-position: center 0%;
  }
  .shared_bs-mobile-cover__1y1Wz {
    background-size: cover;
  }
  .shared_page-title-block__2RQ2b {
    font-size: 3rem;
  }
  .shared_content-wrapper__25COO {
    margin-bottom: 5rem;
  }
  .shared_content-headline__2kvA1 {
    padding: 1rem;
    border-radius: 0.75rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;
  }
  .shared_content-text__3wAYU {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .shared_forever__SsVye {
    font-size: 1.5rem;
    margin-top: 12.5rem;
  }
  .shared_getabone__z_9PT {
    margin-bottom: 15rem;
  }
}

.shared_mb-0__2ZOWR {
  margin-bottom: 0;
}

.roadmap_context-text-list__1NR59 {
  text-align: center;
  list-style: none;
}
.roadmap_content-text__1049T p {
  margin-bottom: 4rem;
}
.roadmap_content-text__1049T p:last-child {
  margin-bottom: 0;
}
.roadmap_text-white__2jyvt {
  color: #fff;
}
.roadmap_item-text__hCLWw {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.roadmap_bold-text__h0n06 {
  font-weight: 800;
}
.benefits_item-text__3oTiW {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}

.faq_item-text__2AmtT {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.faq_underline__2BMRT {
  text-decoration: underline;
}
.faq_hover-opacity__30gXG:hover {
  text-decoration: none;
  opacity: 50%;
}
.community_text-green__23KZ- {
  color: #006516;
}
.community_text-red__Ge-sa {
  color: #b50000;
}
.community_text-purple__1wQ_Q {
  color: #9e00ff;
}
a {
  text-decoration: none;
  color: #000;
}
.community_click-handler__3mDuu {
  display: block;
}
.community_item-text__1C2gm {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.community_bold-text__37NVo {
  font-weight: 800;
}
p {
  margin-bottom: 4rem;
}
p:last-child {
  margin-bottom: 0;
}
.community_mb-10r__33Gmg {
  margin-bottom: 10rem;
}

.community_mt-6-25r__3jBpP {
  margin-top: 6.25rem;
}

@media screen and (max-width: 768px) {
  .community_mb-10r__33Gmg {
    margin-bottom: 5rem;
  }
  .community_mt-6-25r__3jBpP {
    margin-top: 3rem;
  }
}

.cover_no-scroll__3Yc5Y {
  background-image: url(/static/media/BG2.9526f2d8.png);
  background-size: 100vw;
  margin-right: 22.54px;
}

@media screen and (max-width: 768px) {
  .cover_no-scroll__3Yc5Y {
    background-image: url("/static/media/HOME - BONEHEADS - MOBILE.68888510.png");
    background-size: cover;
  }
}


.drop_bg-text__2FuQ2 {
  font-weight: 800;
  font-size: 11.25rem;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: -0.02em;
  text-align: center;
  width: 100%;
}

.drop_heading__28bS9 {
  font-size: 4.5rem;
  line-height: 5rem;
}

@media screen and (max-width: 1280px) {
  .drop_bg-text__2FuQ2 {
    font-size: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .drop_bg-text__2FuQ2 {
    font-size: 3rem;
  }
  .drop_heading__28bS9 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

.landscape_cabana-store__3RM5j {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .landscape_cabana-store__3RM5j {
    background-size: cover;
    background-position: center 0%;
  }
}

.connect_height-100-vh__2O3Y6 {
  height: 100vh;
}
button {
  all: unset;
}
.connect_yellow-btn__1fXJE[disabled] {
  opacity: 0.7;
}
.connect_yellow-btn__1fXJE {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 24px;
  padding: 24px;
  background-color: #fdef50;
}
.connect_icon__tK5Eu {
  width: 40px;
}
.connect_btn-text__2URoI {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  margin-left: 19px;
}
.connect_total-minted___Z6Ud {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.connect_heading__rfvm- {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  color: #fdef50;
}
.connect_minted-value__BCqcW {
  font-weight: 800;
  font-size: 64px;
  line-height: 64px;
  color: #ffffff;
  margin: 32px 0 64px;
}
.connect_cursor-poniter__3s021 {
  cursor: pointer;
}
.connect_qty-container__1Y2Bk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 24px;
  width: 445px;
  margin: 24px 0;
}
.connect_counter-img__DTUy0 {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.connect_counter-value__3tfnR {
  font-weight: bold;
  font-size: 160px;
  line-height: 195px;
  color: #ffffff;
}
.connect_alert__1mKZ6 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding: 16px 24px;
  margin-top: 16px;
}
.connect_alert-text__3kWMG {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.75);
}
.connect_alert-mobile__28vTa {
  display: none;
}
.connect_get-metamask__2hroy {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.connect_d-i-flex__3WZHu {
  display: -webkit-inline-flex;
  display: inline-flex;
}
.connect_sold-out__2whyv {
  font-weight: 800;
  font-size: 96px;
  line-height: 96px;
  color: #ffffff;
  margin: 24px 0 64px;
}
.connect_hashtag-sold__1ooR2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.connect_etherscanlink__2z0l9 {
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .connect_heading__rfvm- {
    font-size: 16px;
    line-height: 24px;
  }
  .connect_minted-value__BCqcW {
    font-size: 48px;
    line-height: 56px;
    margin-top: 16px 0 32px;
  }
  .connect_yellow-btn__1fXJE {
    padding: 12px 18px;
    border-radius: 12px;
  }
  .connect_icon__tK5Eu {
    width: 24px;
  }
  .connect_btn-text__2URoI {
    font-size: 14px;
    line-height: 24px;
  }
  .connect_qty-container__1Y2Bk {
    width: 277px;
    margin: 14.94px 0 20.84px;
  }
  .connect_counter-img__DTUy0 {
    width: 49.8px;
    height: 49.8px;
  }
  .connect_counter-value__3tfnR {
    font-size: 99.5955px;
    line-height: 121px;
  }
  .connect_alert__1mKZ6 {
    display: none;
  }
  .connect_alert-mobile__28vTa {
    display: -webkit-flex;
    display: flex;
    padding: 8px 0;
    margin-top: 16px;
  }
  .connect_alert-mobile__28vTa .connect_alert-text__3kWMG {
    font-weight: 800;
    font-size: 12px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.75);
  }
  .connect_hashtag-sold__1ooR2 {
    font-size: 24px;
  }
  .connect_sold-out__2whyv {
    font-size: 64px;
  }
}

