.cabana-store {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .cabana-store {
    background-size: cover;
    background-position: center 0%;
  }
}
