.bg-text {
  font-weight: 800;
  font-size: 11.25rem;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: -0.02em;
  text-align: center;
  width: 100%;
}

.heading {
  font-size: 4.5rem;
  line-height: 5rem;
}

@media screen and (max-width: 1280px) {
  .bg-text {
    font-size: 7rem;
  }
}

@media screen and (max-width: 768px) {
  .bg-text {
    font-size: 3rem;
  }
  .heading {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
