.item-text {
  font-size: 0.875rem;
  padding-left: 0.75rem;
}
.underline {
  text-decoration: underline;
}
.hover-opacity:hover {
  text-decoration: none;
  opacity: 50%;
}