.height-100-vh {
  height: 100vh;
}
button {
  all: unset;
}
.yellow-btn[disabled] {
  opacity: 0.7;
}
.yellow-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  padding: 24px;
  background-color: #fdef50;
}
.icon {
  width: 40px;
}
.btn-text {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  margin-left: 19px;
}
.total-minted {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
.heading {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  color: #fdef50;
}
.minted-value {
  font-weight: 800;
  font-size: 64px;
  line-height: 64px;
  color: #ffffff;
  margin: 32px 0 64px;
}
.cursor-poniter {
  cursor: pointer;
}
.qty-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  width: 445px;
  margin: 24px 0;
}
.counter-img {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.counter-value {
  font-weight: bold;
  font-size: 160px;
  line-height: 195px;
  color: #ffffff;
}
.alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  margin-top: 16px;
}
.alert-text {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.75);
}
.alert-mobile {
  display: none;
}
.get-metamask {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.d-i-flex {
  display: inline-flex;
}
.sold-out {
  font-weight: 800;
  font-size: 96px;
  line-height: 96px;
  color: #ffffff;
  margin: 24px 0 64px;
}
.hashtag-sold {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.etherscanlink {
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: 16px;
    line-height: 24px;
  }
  .minted-value {
    font-size: 48px;
    line-height: 56px;
    margin-top: 16px 0 32px;
  }
  .yellow-btn {
    padding: 12px 18px;
    border-radius: 12px;
  }
  .icon {
    width: 24px;
  }
  .btn-text {
    font-size: 14px;
    line-height: 24px;
  }
  .qty-container {
    width: 277px;
    margin: 14.94px 0 20.84px;
  }
  .counter-img {
    width: 49.8px;
    height: 49.8px;
  }
  .counter-value {
    font-size: 99.5955px;
    line-height: 121px;
  }
  .alert {
    display: none;
  }
  .alert-mobile {
    display: flex;
    padding: 8px 0;
    margin-top: 16px;
  }
  .alert-mobile .alert-text {
    font-weight: 800;
    font-size: 12px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.75);
  }
  .hashtag-sold {
    font-size: 24px;
  }
  .sold-out {
    font-size: 64px;
  }
}
