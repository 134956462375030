.page-container {
  background: #27c3ec;
  width: 100%;
  min-height: 100vh;
  z-index: 1;
}

.main-container {
  background: #000;
}

/***** Loader animation *****/
.loader-bg {
  background: #000;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: auto;
  position: relative;
  outline: 1px solid transparent;
}
.loader-container .brand-name {
  position: absolute;
  width: 90%;
  animation: brand-name-animation 3s ease-in 1;
}

.loader-container .brand-name:before {
  content: ' ';
  background: radial-gradient(circle at center, transparent 0, black 40%);
  background-size: 200% 500%;
  animation: brand-name-gradient-animation 5s ease-in 1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background-position: -50%;
}

.loader-container .logo {
  position: absolute;
  width: 50vw;
  max-width: 272px;
}

.loader-container .logo:before {
  content: ' ';
  background: radial-gradient(circle at center, transparent 0, black 40%);
  background-size: 500% 500%;
  animation: logo-gradient-animation 3s ease-in 1;
  width: 101%;
  height: 101%;
  display: block;
  position: absolute;
  background-position: 0;
  /* -webkit-backface-visibility: hidden */
}

@keyframes brand-name-gradient-animation {
  0% {
    background-position: 150%;
  }
  50% {
    background-position: 150%;
  }
  100% {
    background-position: -50%;
  }
}

@keyframes logo-gradient-animation {
  0% {
    background-position: 101% 101%;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes brand-name-animation {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**** Fade Animation ****/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-effect {
  display: block; /* Change display inline to block */
  animation: fade 1s;
}

@media screen and (max-width: 768px) {
  .page-container {
    min-height: var(--app-height);
  }
}
