.dropdown {
  position: relative;
}
.dropdown-list {
  position: absolute;
  z-index: 2;
}
.dropdown .dropdown-list {
  padding-top: 0.3125rem;
}

.dropdown .dropdown-list li {
  display: inline-block;
}

/**** Fade Animation ****/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-effect {
  display: block; /* Change display inline to block */
  animation: fade 1s;
}
