.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-page {
  flex-grow: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/**** Fade Animation ****/
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-effect {
  display: block; /* Change display inline to block */
  animation: fade 1s;
}

@media screen and (max-width: 768px) {
  .container {
    min-height: var(--app-height);
  }
}
